import { createRouter,createWebHashHistory,createWebHistory} from "vue-router";


const routes = [
   {
    path: '/sigin',
    name:'登录',
    component: () => import('../viwes/Signin/Signin'),
    meta: {requireAuth: true},
    children: []
   }, 
   {
        path: '/',
        name:'首页',
        component: () => import('../viwes/Index/Index'),
        meta: {requireAuth: true},
        children: [
          {
            path: '/adminsser/adminsser',
            name:'管理员',
            component: () => import('../viwes/AdminUser/AdminUser.vue'),
            meta: {requireAuth: true},
          },
          {
            path: '/goods/goods',
            name:'商品列表',
            component: () => import('../viwes/Goods/Goods.vue'),
            meta: {requireAuth: true},
          },
          {
            path: '/orders/orders',
            name:'订单列表',
            component: () => import('../viwes/Orders/Orders.vue'),
            meta: {requireAuth: true},
          },
          {
            path: '/setting/setting',
            name:'基础设置',
            component: () => import('../viwes/Setting/Setting.vue'),
            meta: {requireAuth: true},
          },
          {
            path: '/user/user',
            name:'会员管理',
            component: () => import('../viwes/User/User.vue'),
            meta: {requireAuth: true},
          }
        ]
     }, 
      
     
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;